import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TimePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material';
import dayjs from 'dayjs';

const StyledTimePicker = styled(TimePicker)(() => ({
    '& .MuiInputLabel-asterisk': {
        color: 'red',
    },
}));

RHFTimeField.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
};

export default function RHFTimeField({ name, disabled, defaultValue, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <StyledTimePicker
                    slotProps={{
                        textField: {
                            variant: 'outlined',
                            error: !!error,
                            helperText: error?.message,
                        },
                    }}
                    format="HH:mm"
                    label="Time"
                    value={value ? dayjs(value, 'HH:mm') : null}
                    onChange={(time) => {
                        onChange(time ? dayjs(time).format('HH:mm') : '');
                    }}
                    onBlur={onBlur}
                    disabled={disabled}
                    sx={{ backgroundColor: disabled ? 'lightgrey' : 'inherit', width: '100%' }}
                    {...other}
                />
            )}
        />
    );
}
